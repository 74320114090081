/* Carousel.css */

.full-width-carousel {
	width: 100%;
	margin: 0 auto;
}

.full-width-slide {
	width: 100vw;
	height: 60vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.full-width-slide img {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.slide-image {
	width: 100%;
	height: auto;
}

.colourthemed-div-bg{
    background: radial-gradient(rgba(235, 99, 99, 1), rgba(243, 208, 208, 0.082));
}